import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="logo.jpg" className="App-logo" alt="logo" />
        <p>
          216 S. Main Street <br />
          Crown Point, IN 46307 <br />
          (219) 552-7538 <br />
          <b>BALLOU-LAW.COM</b>
        </p>
      </header>
    </div>
  );
}

export default App;
